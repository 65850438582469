/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import type { Theme } from 'src/theme';
import { matchPath, useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Hidden, Link, List, Typography, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { useTranslation } from 'react-i18next';
import {
  FaUsers,
  FaSyringe,
  FaFileMedicalAlt,
  FaHeartbeat,
  FaRegAddressBook,
  FaBuilding,
  FaEdit,
  FaPercent,
} from 'react-icons/fa';
import { MdAddBusiness } from 'react-icons/md';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}
interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
}

interface Section {
  items: Item[];
  subheader?: string;
}

const sections: Section[] = [
  {
    subheader: 'management',
    items: [
      {
        title: 'NAVBAR_COLLABORATORS',
        icon: FaUsers,
        items: [
          {
            title: 'NAVBAR_COLLABORATORS',
            icon: FaUsers,
            href: '/app/collaborators/list',
          },
          {
            title: 'Colaboradores desligados',
            icon: FaUsers,
            href: '/app/collaborators/fired/list',
          },
        ],
      },
      {
        title: 'NAVBAR_VACCINES',
        icon: FaSyringe,
        href: '/app/vaccines',
      },
      {
        title: 'NAVBAR_SICK_LEAVE',
        icon: FaFileMedicalAlt,
        href: '/app/sick-leaves',
      },
      {
        title: 'NAVBAR_HEALTH_DATA',
        icon: FaHeartbeat,
        href: '/app/health-data',
      },
      {
        title: 'NAVBAR_EMERGENCY_INFORMATION',
        icon: FaRegAddressBook,
        href: '/app/emergency-information',
      },
      {
        title: 'NAVBAR_ENTERPRISE_GROUP',
        icon: FaBuilding,
        items: [
          {
            title: 'NAVBAR_ENTERPRISE_GROUP_CREATE',
            icon: MdAddBusiness,
            href: '/app/enterprise-group/create',
          },
          {
            title: 'NAVBAR_ENTERPRISE_GROUP_EDIT',
            icon: FaEdit,
            href: '/app/enterprise-group/edit',
          },
          {
            title: 'NAVBAR_ENTERPRISE_GROUP_CONVERSION',
            icon: FaPercent,
            href: '/app/enterprise-group/conversion',
          },
          {
            title: 'Gerar relatórios',
            icon: FaPercent,
            href: '/app/enterprise-group/reports',
          },
        ],
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }: { items: Item[]; pathname: string; depth?: number }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} info={item.info} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} info={item.info} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logoContainer: {
    background: theme.palette.background.logo,
    padding: '.6rem',
    borderRadius: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 20px 30px',
  },
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  const { t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/home">
              <Logo backgroundImage={`url(${user.enterprise?.logo[0].url})`} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {user.name + ' ' + user.surname}
            </Link>
            <Typography variant="body2" color="textSecondary">
              <Link component={RouterLink} to="/pricing">
                {user.enterprise?.commercialName}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List key={section.subheader}>
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <Link
            style={{ textDecoration: 'none' }}
            variant="subtitle1"
            color="secondary"
            component={RouterLink}
            to="/app/support"
          >
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textPrimary">
                {t('NAVBAR_NEED_HELP_TEXT')}
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontWeight: 'bold',
                  color: '#008ffb',
                }}
              >
                {t('NAVBAR_CHECK_DOCS')}
              </Typography>
              <img
                style={{
                  width: '100%',
                }}
                src="/static/images/suporte_btn.png"
                alt="Suporte"
              />
              <Typography
                variant="h6"
                style={{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#008ffb',
                }}
              >
                Fale agora com um consultor
              </Typography>
              <Typography
                style={{
                  fontSize: '11pt',
                  textAlign: 'center',
                }}
              >
                Disponível de segunda a sexta, das 8h ao meio dia
              </Typography>
            </Box>
          </Link>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
